import React, { ReactElement, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Icon, Image, Popup } from "semantic-ui-react"

import CarrierLogo from "components/Carrier/CarrierLogo"
import {
  ParcelPartnerContainer,
  PartnerEmoji,
  PartnerInfo,
  PartnerInfoButton,
  PartnerInfoColumn,
  PartnerInfoDoubleColumns,
  PartnerInfoLine,
  PartnerLabel,
  PartnerName,
  PartnerStatus,
  PartnerTitle,
  PartnerType,
} from "components/Parcels/ParcelPartner/ParcelPartner.styled"
import { apiGetDHLPickupHours } from "services/companies"
import { Address } from "types/address.types"
import { CompanyAddress } from "types/company.types"
import { Meeting } from "types/meeting.types"
import { convertDateToMeetingDate } from "utils/date"
import ClockIcon from "assets/components/icons/clock.png"
import { ShipmentParcelMeta } from "types/shipment.types"

interface ParcelPartnerProps {
  emoji: string
  type: string
  partnerName: string
  status: string
  parcelNo: string
  companyAddress?: CompanyAddress
  shipmentNo?: string
  meeting?: Meeting
  keeperAddress: Address
  estimatedDeliveryDate?: string
  onVerify?: () => void
  shipmentsMeta?: ShipmentParcelMeta
}

const ParcelPartner = ({
  emoji,
  type,
  partnerName,
  status,
  parcelNo,
  companyAddress,
  shipmentNo,
  meeting,
  keeperAddress,
  estimatedDeliveryDate,
  onVerify,
  shipmentsMeta,
}: ParcelPartnerProps): ReactElement => {
  const dispatch = useDispatch()
  const [copiedText, setTextCopied] = useState({
    companyAddressEmail: false,
    shipmentNo: false,
    meeting: false,
    pickupHours: false,
    code: false,
  })
  const [pickupHours, setPickupHours] = useState({
    loaded: false,
    hours: null,
  })

  const _copyText = (type, text) => {
    setTextCopied({ ...copiedText, [type]: true })
    // eslint-disable-next-line no-undef
    navigator.clipboard.writeText(text)

    setTimeout(() => {
      setTextCopied({ ...copiedText, [type]: false })
    }, 1000)
  }

  const _getCarrierTrackingLink = (carrierName: string, parcelNo: string): string => {
    if (carrierName === "GLS") return `https://gls-group.eu/FR/fr/suivi-colis.html?match=${parcelNo}`
    if (carrierName === "DHL")
      return `https://www.dhl.com/fr-fr/home/tracking/tracking-express.html?submit=1&tracking-id=${parcelNo}`
    if (carrierName === "Relais Colis") return `https://mypickme2b.aftership.com/${parcelNo}`
    if (carrierName === "Colissimo") return `https://www.laposte.fr/outils/suivre-vos-envois?code=${parcelNo}`
    if (carrierName === "Geodis") return `https://espace-client.geodis.com/services/destinataires`

    return ""
  }

  /**
   * DHL Only.
   * Get pickup hours for a zipcode and a city.
   * (based on the zipcode/city of the keeper address assigned to this parcel).
   */
  const getDHLPickupHours = async () => {
    if (partnerName === "DHL" && type === "Expédition (Aller)") {
      const pickupHoursRequest = await dispatch(
        apiGetDHLPickupHours({ city: keeperAddress.city, zipcode: keeperAddress.zipcode }),
      )

      const pickupHours = pickupHoursRequest?.payload?.data

      if (pickupHours !== null) {
        setPickupHours({
          loaded: true,
          hours: `${pickupHours?.startHour} - ${pickupHours?.endHour}`.replace(/:/g, "h"),
        })
      }
    }
  }

  // On load, if DHL carrier and type is "Expédition (Aller)", we load the pickup hours for DHL
  useEffect(() => {
    if (partnerName === "DHL" && type === "Expédition (Aller)") {
      getDHLPickupHours()
    }
  }, [])

  return (
    <ParcelPartnerContainer>
      <PartnerTitle>
        <PartnerEmoji>{emoji}</PartnerEmoji>
        <PartnerType>{type}</PartnerType>
      </PartnerTitle>

      <PartnerName>
        <CarrierLogo name={partnerName} />
      </PartnerName>

      {type === "Expédition (Aller)" && (
        <PartnerInfoDoubleColumns>
          <PartnerInfoColumn>
            <PartnerInfo>
              <Popup
                content={copiedText.companyAddressEmail ? "Copié ✅" : companyAddress?.contact}
                size="small"
                position="bottom center"
                trigger={
                  <div
                    style={{ cursor: "pointer" }}
                    className="address-id"
                    onClick={() => _copyText("companyAddressEmail", companyAddress?.contact)}
                  >
                    <PartnerLabel>Agence</PartnerLabel>
                    {companyAddress?.name || <i>Non renseignée</i>}
                  </div>
                }
              />
            </PartnerInfo>
            {pickupHours.loaded && (
              <PartnerInfo>
                <Popup
                  content={copiedText.pickupHours ? "Copié ✅" : "Horaires de collecte pour cette agence"}
                  size="small"
                  position="bottom center"
                  trigger={
                    <div
                      style={{ cursor: "pointer" }}
                      className="address-id"
                      onClick={() => _copyText("pickupHours", pickupHours.hours)}
                    >
                      <PartnerLabel>Créneau de collecte</PartnerLabel>
                      {pickupHours.hours}
                    </div>
                  }
                />
              </PartnerInfo>
            )}
          </PartnerInfoColumn>
          <PartnerInfoColumn>
            {!!shipmentNo && (
              <PartnerInfo>
                <Popup
                  content={copiedText.shipmentNo ? "Copié ✅" : "Numéro d'expédition (AWB DHL)"}
                  size="small"
                  position="bottom center"
                  trigger={
                    <div
                      style={{ cursor: "pointer" }}
                      className="address-id"
                      onClick={() => _copyText("shipmentNo", shipmentNo)}
                    >
                      <PartnerLabel>Numéro d&apos;expédition</PartnerLabel>
                      {shipmentNo}
                    </div>
                  }
                />
              </PartnerInfo>
            )}
            {!!estimatedDeliveryDate && (
              <PartnerInfo>
                <PartnerLabel>Date de livraison estimée</PartnerLabel>
                {new Date(estimatedDeliveryDate).toLocaleDateString()}
              </PartnerInfo>
            )}
          </PartnerInfoColumn>
        </PartnerInfoDoubleColumns>
      )}

      {type === "Expédition (Retour)" && (
        <PartnerInfoColumn>
          {!!shipmentNo && (
            <PartnerInfo>
              <Popup
                content={copiedText.shipmentNo ? "Copié ✅" : "Numéro d'expédition (AWB DHL)"}
                size="small"
                position="bottom center"
                trigger={
                  <div
                    style={{ cursor: "pointer" }}
                    className="address-id"
                    onClick={() => _copyText("shipmentNo", shipmentNo)}
                  >
                    <PartnerLabel>Numéro d&apos;expédition</PartnerLabel>
                    {shipmentNo}
                  </div>
                }
              />
            </PartnerInfo>
          )}
          {!!meeting && (
            <PartnerInfo directionRow>
              <Popup
                content={copiedText.meeting ? "Copié ✅" : "Rendez-vous de collecte"}
                size="small"
                position="bottom center"
                trigger={
                  <div
                    style={{ cursor: "pointer" }}
                    className="address-id"
                    onClick={() => _copyText("meeting", convertDateToMeetingDate(meeting))}
                  >
                    <PartnerLabel>Rendez-vous</PartnerLabel>⏰ {convertDateToMeetingDate(meeting)}
                  </div>
                }
              />
              {shipmentsMeta && (
                <Popup
                  content={
                    copiedText.code
                      ? "Copié ✅"
                      : (shipmentsMeta.cotransportationCode && "Code de cotransporteur") ||
                        (shipmentsMeta.referenceCode && "Le code de référence")
                  }
                  size="small"
                  position="bottom center"
                  trigger={
                    <div
                      style={{ cursor: "pointer" }}
                      className="address-id"
                      onClick={() =>
                        _copyText("code", shipmentsMeta.cotransportationCode || shipmentsMeta.referenceCode)
                      }
                    >
                      <PartnerLabel>
                        {shipmentsMeta.cotransportationCode && "Code de cotransporteur"}
                        {shipmentsMeta.referenceCode && "Le code de référence"}
                      </PartnerLabel>
                      {shipmentsMeta.cotransportationCode || shipmentsMeta.referenceCode}
                    </div>
                  }
                />
              )}
            </PartnerInfo>
          )}
        </PartnerInfoColumn>
      )}
      {type == "Demande de retour a valider" && (
        <PartnerInfo>
          <PartnerInfoLine>{parcelNo}</PartnerInfoLine>
          <PartnerInfoLine>
            <Image src={ClockIcon} style={{ padding: "5px" }} />
            {shipmentNo}
          </PartnerInfoLine>
          <PartnerInfoLine>
            <PartnerInfoButton onClick={onVerify}>Verifier</PartnerInfoButton>
          </PartnerInfoLine>
        </PartnerInfo>
      )}

      {type !== "Demande de retour a valider" && status !== null && (
        <PartnerStatus
          onClick={() => {
            if (type === "Expédition (Aller)") {
              // eslint-disable-next-line no-undef
              window.open(_getCarrierTrackingLink(partnerName, parcelNo), "_blank")
            }
          }}
        >
          {status} {type === "Expédition (Aller)" && <Icon name="linkify" />}
        </PartnerStatus>
      )}
    </ParcelPartnerContainer>
  )
}

export default ParcelPartner
