import React, { ReactElement, useEffect, useState } from "react"
import { Icon } from "semantic-ui-react"
import styled from "styled-components"
import { ParcelEventCode, ParcelEventReason } from "types/parcel-event.types"

export const ParcelTestingContainer = styled.div`
  background-color: #1e232c;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
`

export const ParcelTestingTitle = styled.div`
  font-size: 14px;
  color: #fff;
`

export const ParcelTestingSubtitle = styled.div`
  font-size: 9px;
  color: #fff;
  font-style: italic;
  margin-top: 20px;
  margin-bottom: 10px;
`

const MiniButtonTestingContainer = styled.div`
  padding: 3px 6px;
  margin-right: 5px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 9px;
  position: relative;
  background-color: #333542;
  width: fit-content;
  align-self: flex-start;
  color: #fff;
  margin-bottom: 5px;

  &:hover {
    background-color: #3e404f;
  }

  &:first-of-type {
    margin-left: 3px;
  }
`

const MiniButtonTesting = ({ apiCallback, title }): ReactElement => {
  const [buttonClicked, setButtonClicked] = useState(false)

  useEffect(() => {
    if (buttonClicked === true) {
      setTimeout(() => setButtonClicked(false), 1000)
    }
  }, [buttonClicked])

  return (
    <MiniButtonTestingContainer
      onClick={() => {
        setButtonClicked(true)
        apiCallback()
      }}
    >
      {buttonClicked ? "Sent ✅" : title}
    </MiniButtonTestingContainer>
  )
}

interface ParcelTestingProps {
  simulateParcelEvent: ({
    eventCode,
    eventReason,
  }: {
    eventCode: ParcelEventCode
    eventReason?: ParcelEventReason
  }) => void
}

/**
 * Component used for testing purposes - Simulate specifc events attached to one parcel
 */
export default function ParcelTesting({ simulateParcelEvent }: ParcelTestingProps): ReactElement {
  const [actionsDisplayed, setActionsDisplayed] = useState(true)

  return (
    <ParcelTestingContainer>
      <ParcelTestingTitle>
        🧰 Testing - Simulate event{" "}
        <Icon
          size={"small"}
          name={actionsDisplayed ? "eye" : "eye slash"}
          onClick={() => setActionsDisplayed(!actionsDisplayed)}
        />
      </ParcelTestingTitle>
      {actionsDisplayed && (
        <span>
          <ParcelTestingSubtitle>As the Delivery Carrier</ParcelTestingSubtitle>

          <MiniButtonTesting
            apiCallback={() =>
              simulateParcelEvent({ eventCode: ParcelEventCode.CARRIER_BTOC_DELIVERY_OUT_FOR_DELIVERY })
            }
            title={"🚛 Parcel out for delivery from Carrier"}
          />
          <MiniButtonTesting
            apiCallback={() =>
              simulateParcelEvent({
                eventCode: ParcelEventCode.CARRIER_BTOC_DELIVERY_DELIVERY_FAILURE,
                eventReason: ParcelEventReason.KEEPER_ABSENT,
              })
            }
            title={"🚛 Keeper delivery failure (Keeper absent)"}
          />
          <MiniButtonTesting
            apiCallback={() =>
              simulateParcelEvent({ eventCode: ParcelEventCode.CARRIER_BTOC_DELIVERY_DELIVERY_FAILURE })
            }
            title={"🚛 Keeper delivery failure (no reason)"}
          />
        </span>
      )}
    </ParcelTestingContainer>
  )
}
