import React from "react"
import { Button, Header, Icon, Modal } from "semantic-ui-react"
import { AdminParcelEventReason, ParcelStatus } from "types/parcel.types"
import { AdminParcelEventType } from "../../getParcelActions"
import { ReturnModalContent } from "./ReturnToPlanModal.styled"

interface ReturnToPlanModalProps {
  isOpened: boolean
  openReturnToPlanModal: (type: AdminParcelEventType) => void
  cancelReturnToPlanModal: () => void
  parcelStatus: ParcelStatus
  submitAdminEvent: (reason?: AdminParcelEventReason) => Promise<void>
}

export const ReturnToPlanModal = ({
  isOpened,
  openReturnToPlanModal,
  cancelReturnToPlanModal,
  parcelStatus,
  submitAdminEvent,
}: ReturnToPlanModalProps) => {
  return (
    <Modal
      basic
      onClose={cancelReturnToPlanModal}
      onOpen={() => openReturnToPlanModal(AdminParcelEventType.RETURN_TO_PLAN)}
      open={isOpened}
      size="small"
    >
      <Header icon>
        <Icon name="attention" />
        En êtes vous sûr?
      </Header>
      <ReturnModalContent>
        Nous enverrons une notification push et retournerons le questionnaire au keeper si vous confirmez.
      </ReturnModalContent>
      <Modal.Actions>
        <Button basic color="red" inverted onClick={cancelReturnToPlanModal}>
          <Icon name="remove" /> Non !
        </Button>
        <Button
          color="green"
          inverted
          onClick={async () => {
            await submitAdminEvent(
              parcelStatus === ParcelStatus.DELIVERED_TO_KEEPER
                ? AdminParcelEventReason.OTHER
                : AdminParcelEventReason.RETURN_PLANNING_BY_KEEPER,
            )
            cancelReturnToPlanModal()
          }}
        >
          <Icon name="checkmark" /> Confirmer
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
