export const returnRequestLabels = {
  reason: "Raison",
  pickupDate: "Date de collecte",
  interval: "Horaires",
  dimensions: "Taille",
  weight: "Poids",
  fullName: "Nom du destinataire",
  phone: "Téléphone",
  address: "Adresse du destinataire",
  other: "Informations complémentaires",
}
