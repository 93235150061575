import React, { useState, useCallback, useMemo, useEffect, ReactElement } from "react"
import moment from "moment"
import FlatList from "flatlist-react"
import { CalendarWrapper, Container, MonthParagraph } from "./HorizontalCalendar.styled"
import { CalendarDateItem } from "../CalendarDateItem/CalendarDateItem"

interface HorizontalCalendarProps {
  onDateSelected: (date: string) => void
  daysWithAtLeastOneAvailability: string[] // List of days with at least one availability format YYYY-MM-DD
  startDate?: Date
}

export function HorizontalCalendar({
  onDateSelected,
  daysWithAtLeastOneAvailability,
  startDate,
}: HorizontalCalendarProps): ReactElement {
  // CALCULATE CALENDAR DATES TO DISPLAY
  const [calendarDates] = useState(() => {
    const dates = []

    // Parse the start and end dates
    const currentDate = moment(startDate).subtract(7, "days")
    const finalDate = moment(startDate).add(15, "days")

    // Loop through each day from startDate to endDate (inclusive)
    while (currentDate.isSameOrBefore(finalDate, "day")) {
      dates.push(currentDate.format("YYYY-MM-DD")) // Add ISO string format to the array
      currentDate.add(1, "day") // Move to the next day
    }

    return dates
  })

  const [firstViewableDate, setFirstViewableDate] = useState(calendarDates[0])
  const currentMonth = useMemo(() => moment(firstViewableDate).format("MMMM YYYY").toUpperCase(), [firstViewableDate])

  // Display a small point if the day has at least one availability
  const dateItems = useMemo(() => {
    return calendarDates.map(date => {
      const activated = daysWithAtLeastOneAvailability.includes(date)

      return { date, activated }
    })
  }, [calendarDates, daysWithAtLeastOneAvailability])

  const firstSelectedDate = useMemo(() => {
    return calendarDates[0]
  }, [dateItems, calendarDates])

  const [selectedDate, setSelectedDate] = useState(firstSelectedDate)

  useEffect(() => {
    onDateSelected && onDateSelected(firstSelectedDate || calendarDates[0])
  }, [firstSelectedDate, onDateSelected, calendarDates[0]])

  const _handleViewableItemsChanged = useCallback(({ viewableItems }) => {
    const itemInfo = viewableItems.find(({ isViewable }) => isViewable)
    itemInfo && itemInfo.item && setFirstViewableDate(itemInfo.item.date)
  }, [])

  function _handleDateSelected(date: string) {
    setSelectedDate(date)
    onDateSelected && onDateSelected(date)
  }

  function _renderDateItem(item) {
    return (
      <CalendarDateItem
        date={item.date}
        key={item.date}
        selected={item.date === selectedDate}
        activated={item.activated}
        onClick={_handleDateSelected}
      />
    )
  }

  return (
    <CalendarWrapper>
      <MonthParagraph>{currentMonth}</MonthParagraph>
      <Container>
        <FlatList
          contentContainerStyle={{ paddingLeft: 32, overflowAnchor: "auto" }}
          list={dateItems}
          renderItem={_renderDateItem}
          onViewableItemsChanged={_handleViewableItemsChanged}
          onEndReached={() => true}
          onEndReachedThreshold={0.5}
          showsHorizontalScrollIndicator={false}
          horizontal
          renderOnScroll={true}
        />
      </Container>
    </CalendarWrapper>
  )
}
