import styled from "styled-components"
import { Button, Input, Modal } from "semantic-ui-react"
import { COLOR } from "utils/color"

export const ReturnRequestModalHeader = styled(Modal.Header)`
  border-bottom: 0 !important;
`

export const ReturnRequestModal = styled(Modal)`
  border-radius: 20px !important;
  padding: 2px 18px;
  font-family: Poppins;
`

export const ReturnRequestModalActions = styled(Modal.Actions)`
  border-top: 0 !important;
  background-color: ${COLOR.WHITE} !important;
`

export const ReturnRequestModalButton = styled(Button)`
  border-radius: 10px !important;
  background-color: ${COLOR.MEDIUM_SUPER_LIGHT_GREY} !important;
`

export const ReturnRequestModalButtonActive = styled(ReturnRequestModalButton)`
  background-color: ${COLOR.GREEN} !important;
`
export const ReturnRequestTitle = styled.h2`
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: ${COLOR.DARK_BLUE};
`

export const ReturnRequestFormRow = styled.div`
  display: flex;
  flex-direction: row;
`

export const ReturnRequestFormCol = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 50%;
  font-size: 15px;
`

export const ReturnRequestFormInput = styled(Input)`
  margin-top: 2.5rem;
`

export const ReturnRequestDescription = styled.h2`
  font-size: 12px;
  font-style: italic;
  line-height: 18px;
  white-space: pre;
  text-align: left;
`

export const ReturnRequestItem = styled.div`
  display: inline-block;
  margin-bottom: 10px;
`

export const ReturnRequestItemKey = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  margin-right: 5px;
  display: inline-block;
`

export const ReturnRequestItemValue = styled.div`
  display: inline-block;
  font-size: 14px;
  line-height: 21px;
`

export const CancelReturnRequestButton = styled.div`
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  text-align: left;
  color: ${COLOR.SUPER_DARK_RED};
  margin-top: 20px;
  cursor: pointer;
  padding: 3px 0;
  text-decoration: underline;
`
