import moment from "moment/moment"
import { GetParcelReturnRequestQuery } from "types/parcel.types"
import { CarriersName } from "types/carrier.types"

export const getReturnRequestConvertedValue = (
  key: keyof GetParcelReturnRequestQuery["getReturnRequestDetails"]["returnBtocRequest"],
  value: string | number | { start: string; end: string },
) => {
  let returnValue = value
  if (key === "pickupDate" && typeof value === "string") {
    returnValue = moment.utc(value).startOf("day").format("DD/MM/yyyy")
  }
  if (key === "interval" && typeof value !== "string" && typeof value !== "number") {
    returnValue = `${moment(`${value.start}`).format("HH")}H - ${moment(value.end).format("HH")}H`
  }

  if (key === "weight") {
    switch (value) {
      case 1:
        returnValue = "Léger (moins de 1kg)"
        break
      case 5:
        returnValue = "Moyen (entre 1kg et 5kg)"
        break
      case 10:
        returnValue = "Lourd (plus de 5kg)"
        break
    }
  }

  return returnValue
}

export const getValidationModalTexts = (clientName: CarriersName) => {
  switch (clientName) {
    case CarriersName.COLISSIMO:
    case CarriersName.GEODIS:
    case CarriersName.POST_OFFICE:
    case CarriersName.TUT_TUT:
      return {
        title: `Une fois la demande créée sur ${
          clientName === CarriersName.TUT_TUT ? "Tut-Tut" : "La Poste-Bureau"
        }, veuillez la valider ici. \nDes emails seront envoyés automatiquement au Keeper et à l’agence.`,
        label: "Code de co-transporteur",
        key: "cotransportationCode",
      }
    case CarriersName.GLS:
      return {
        title:
          "Une fois la demande créée sur Pick&Ship, veuillez la valider ici. \nUn email de confirmation sera envoyé au Keeper après votre validation.",
        label: "Code de référence",
        key: "referenceCode",
      }
    default:
      return {
        title: "",
        label: null,
        key: null,
      }
  }
}
