import { AdminParcelEventReason, Parcel, ParcelFlag, ParcelStatus } from "types/parcel.types"
import { displayParcelFlag } from "utils/displayParcelFlag"
import { AdminUserRole } from "types/admin-user.types"
import { CarriersName } from "types/carrier.types"
import { OrderType } from "types/order.types"

const POSSIBLE_STATUSES_ADMIN_EVENT_DELIVERED_TO_KEEPER = [
  "CREATED",
  "IN_PREPARATION",
  "READY_TO_SHIP",
  "IN_TRANSIT",
  "IN_DELIVERY",
  "KEEPER_DELIVERY_FAILURE",
  "CANCELLED",
  "DELIVERED_TO_RECIPIENT",
  "RETURN_COMPLETED",
]
const POSSIBLE_STATUSES_ADMIN_EVENT_DELIVERED_TO_RECIPIENT = [
  "CREATED",
  "IN_PREPARATION",
  "READY_TO_SHIP",
  "IN_TRANSIT",
  "IN_DELIVERY",
  "DELIVERED_TO_KEEPER",
  "KEEPER_DELIVERY_FAILURE",
  "RETURN_TO_PLAN",
]
const POSSIBLE_STATUSES_ADMIN_EVENT_CANCELLATION = [
  "CREATED",
  "IN_PREPARATION",
  "READY_TO_SHIP",
  "IN_TRANSIT",
  "IN_DELIVERY",
  "KEEPER_DELIVERY_FAILURE",
  "WAITING_FOR_DROPOFF",
]
const POSSIBLE_STATUSES_ADMIN_EVENT_REASSIGNMENT = [
  "CREATED",
  "IN_PREPARATION",
  "READY_TO_SHIP",
  "IN_TRANSIT",
  "IN_DELIVERY",
  "KEEPER_DELIVERY_FAILURE",
  "CANCELLED",
]

const AUTHORIZED_ROLES_FOR_CANCEL_KEEPER_RECEPTION = [
  AdminUserRole.ADMIN,
  AdminUserRole.HEAD_OF_CARE,
  AdminUserRole.CARE_MANAGER,
  AdminUserRole.CARE_SENIOR,
  AdminUserRole.CARE_EXTERNAL,
]

export interface ParcelAction {
  key: number
  text: string
  value: AdminParcelEventType | string
  active: boolean
  secondOptions?: {
    value: string
    label: string
  }[]
  options: {
    value: string
    label: string
  }[]
}

export enum AdminParcelEventType {
  IN_TRANSIT = "IN_TRANSIT",
  DELIVERED_TO_KEEPER = "DELIVERED_TO_KEEPER",
  DELIVERED_TO_RECIPIENT = "DELIVERED_TO_RECIPIENT",
  CANCELLATION = "CANCELLATION",
  REASSIGNMENT = "REASSIGNMENT",
  ASK_RETURN = "ASK_RETURN",
  RETURN_TO_PLAN = "RETURN_TO_PLAN",
  ADMIN_RETURNING_SUCCESS = "ADMIN_RETURNING_SUCCESS",
  RETURN_COMPLETED = "RETURN_COMPLETED",
  KEEPER_DELIVERY_FAILURE = "KEEPER_DELIVERY_FAILURE",
  CANCEL_RETURN_REQUESTED = "CANCEL_RETURN_REQUESTED",
  REMOVE_FLAG = "REMOVE_FLAG",
  ADD_FLAG = "ADD_FLAG",
  CANCEL_KEEPER_RECEPTION_SUCCESS = "CANCEL_KEEPER_RECEPTION_SUCCESS",
  DROPPED_TO_KEEPER = "DROPPED_TO_KEEPER",
  DROPOFF_DELIVERED = "DROPOFF_DELIVERED",
}

export const getParcelActions = (
  parcel: Parcel,
  carrierName: string,
  role: AdminUserRole,
  orderType: OrderType = OrderType.BtoC,
): ParcelAction[] => [
  {
    key: 7,
    text: "Repasser en transit",
    value: AdminParcelEventType.IN_TRANSIT,
    active: ["IN_DELIVERY", "KEEPER_DELIVERY_FAILURE"].includes(parcel.status) && orderType === OrderType.BtoC,
    options: [
      {
        value: AdminParcelEventReason.KEEPER_ABSENT,
        label: "Le Keeper était absent",
      },
      {
        value: AdminParcelEventReason.MISSING_ADDRESS_DATA,
        label: "Données d'adresse manquantes",
      },
      {
        value: AdminParcelEventReason.REFUSED_BY_KEEPER,
        label: "Refusé par le Keeper",
      },
      {
        value: AdminParcelEventReason.OTHER,
        label: "Autre",
      },
    ],
  },
  {
    key: 20,
    text: "Annuler le retour",
    value: AdminParcelEventType.CANCEL_RETURN_REQUESTED,
    active: ["RETURN_REQUESTED"].includes(parcel.status) && orderType === OrderType.BtoC,
    options: [
      {
        value: AdminParcelEventReason.DELIVERED_TO_RECIPIENT,
        label: "Le destinataire a récupéré son colis",
      },
      {
        value: AdminParcelEventReason.CARRIER_DID_NOT_SHOW_UP,
        label: "Echec de collecte transporteur",
      },
      {
        value: AdminParcelEventReason.KEEPER_MISSED_APPOINTMENT,
        label: "Echec de collecte Keeper",
      },
      {
        value: AdminParcelEventReason.CARRIER_CHANGED_AVAILABILITY,
        label: "Report de la collecte par le transporteur",
      },
      {
        value: AdminParcelEventReason.KEEPER_ASKED_RESCHEDULE,
        label: "Report de la collecte par le keeper",
      },
      {
        value: AdminParcelEventReason.OTHER,
        label: "Autre",
      },
    ],
  },
  {
    key: 14,
    text: "Échec de livraison Keeper",
    value: AdminParcelEventType.KEEPER_DELIVERY_FAILURE,
    active: ["IN_TRANSIT", "IN_DELIVERY"].includes(parcel.status) && orderType === OrderType.BtoC,
    options: [
      {
        value: AdminParcelEventReason.KEEPER_ABSENT,
        label: "Le Keeper était absent",
      },
      {
        value: AdminParcelEventReason.REFUSED_BY_KEEPER,
        label: "Refusé par le Keeper",
      },
      {
        value: AdminParcelEventReason.OTHER,
        label: "Autre",
      },
    ],
  },
  {
    key: 3,
    text: "Livré au Keeper",
    value: AdminParcelEventType.DELIVERED_TO_KEEPER,
    active: POSSIBLE_STATUSES_ADMIN_EVENT_DELIVERED_TO_KEEPER.includes(parcel.status) && orderType === OrderType.BtoC,
    options: [
      {
        value: AdminParcelEventReason.KEEPER_DID_NOT_SCAN,
        label: "Le Keeper n'a pas pu scanner",
      },
      {
        value: AdminParcelEventReason.OTHER,
        label: "Autre",
      },
    ],
  },
  {
    key: 4,
    text: "Livré au Destinataire",
    value: AdminParcelEventType.DELIVERED_TO_RECIPIENT,
    active:
      POSSIBLE_STATUSES_ADMIN_EVENT_DELIVERED_TO_RECIPIENT.includes(parcel.status) && orderType === OrderType.BtoC,
    options: [
      {
        value: AdminParcelEventReason.KEEPER_DID_NOT_SCAN,
        label: "Le Keeper n'a pas pu scanner",
      },
      {
        value: AdminParcelEventReason.DELIVERED_TO_RECIPIENT,
        label: "Livré directement au destinataire",
      },
      {
        value: AdminParcelEventReason.DELIVERED_TO_PICKUP_POINT,
        label: "Livré en point relais classique",
      },
      {
        value: AdminParcelEventReason.OTHER,
        label: "Autre",
      },
    ],
  },
  {
    key: 50,
    text: "Déposé chez le Keeper",
    value: AdminParcelEventType.DROPPED_TO_KEEPER,
    active: [ParcelStatus.WAITING_FOR_DROPOFF].includes(parcel.status) && orderType === OrderType.CtoB,
    options: [
      {
        value: AdminParcelEventReason.KEEPER_DID_NOT_SCAN,
        label: "Le Keeper n'a pas pu scanner",
      },
      {
        value: AdminParcelEventReason.OTHER,
        label: "Autre",
      },
    ],
  },
  {
    key: 51,
    text: "Collecté par le transporteur",
    value: AdminParcelEventType.DROPOFF_DELIVERED,
    active: [ParcelStatus.DROPPED_TO_KEEPER].includes(parcel.status) && orderType === OrderType.CtoB,
    options: [
      {
        value: AdminParcelEventReason.KEEPER_DID_NOT_SCAN,
        label: "Le Keeper n'a pas pu scanner",
      },
      {
        value: AdminParcelEventReason.OTHER,
        label: "Autre",
      },
    ],
  },
  {
    key: 11,
    text: "À retourner - passer commande",
    value: "ASK_RETURN",
    active:
      [ParcelStatus.DELIVERED_TO_KEEPER, ParcelStatus.RECIPIENT_DELIVERY_FAILURE, ParcelStatus.RETURN_TO_PLAN].includes(
        parcel.status,
      ) && orderType === OrderType.BtoC,
    options: [
      {
        value: AdminParcelEventReason.REFUSED_BY_RECIPIENT,
        label: "Colis refusé par le destinataire",
      },
      {
        value: AdminParcelEventReason.RECIPIENT_ABSENT,
        label: "Destinataire injoignable",
      },
      {
        value: AdminParcelEventReason.OTHER,
        label: "Autre",
      },
    ],
  },
  {
    key: 15,
    text: "À retourner - planification par Keeper",
    value: AdminParcelEventType.RETURN_TO_PLAN,
    active:
      [ParcelStatus.DELIVERED_TO_KEEPER, ParcelStatus.RETURN_REQUESTED, ParcelStatus.RETURN_TO_PLAN].includes(
        parcel.status,
      ) &&
      orderType === OrderType.BtoC &&
      carrierName !== CarriersName.RELAISCOLIS,
    options: [
      {
        value: AdminParcelEventReason.RETURN_PLANNING_BY_KEEPER,
        label: "RETURN_PLANNING_BY_KEEPER",
      },
      {
        value: AdminParcelEventReason.OTHER,
        label: "Autre",
      },
    ],
  },
  {
    key: 21,
    text: "Annuler la réception Keeper",
    value: AdminParcelEventType.CANCEL_KEEPER_RECEPTION_SUCCESS,
    active:
      ["DELIVERED_TO_KEEPER"].includes(parcel.status) &&
      AUTHORIZED_ROLES_FOR_CANCEL_KEEPER_RECEPTION.includes(role) &&
      orderType === OrderType.BtoC,
    options: [
      {
        value: AdminParcelEventReason.COLLECTED_BY_CLIENT,
        label: "Colis C2B - Repris par le client",
      },
      {
        value: AdminParcelEventReason.COLLECTED_BY_CARRIER,
        label: "Colis repris par le transporteur",
      },
      {
        value: AdminParcelEventReason.OTHER,
        label: "Autre",
      },
    ],
  },
  {
    key: 8,
    text: "Le colis a été scanné en retour",
    value: AdminParcelEventType.ADMIN_RETURNING_SUCCESS,
    active: ["RETURN_REQUESTED"].includes(parcel.status) && orderType === OrderType.BtoC,
    options: [],
  },
  {
    key: 2,
    text: "Retour terminé",
    value: "RETURN_COMPLETED",
    active: ["RETURNING"].includes(parcel.status) && orderType === OrderType.BtoC,
    options: [],
  },
  {
    key: 5,
    text: "Réassigner le colis",
    value: AdminParcelEventType.REASSIGNMENT,
    active:
      POSSIBLE_STATUSES_ADMIN_EVENT_REASSIGNMENT.includes(parcel.status) &&
      !["Geodis", "Relais Colis"].includes(carrierName) &&
      orderType === OrderType.BtoC,
    options: [
      {
        value: AdminParcelEventReason.DELIVERY_OUT_OF_KEEPER_AVAILABILITIES,
        label: "Livraison hors dispo Keeper",
      },
      {
        value: AdminParcelEventReason.KEEPER_CHANGED_AVAILABILITIES,
        label: "Changement dispo Keeper",
      },
      {
        value: AdminParcelEventReason.DELIVERED_TO_PREVIOUSLY_ASSIGNED_KEEPER,
        label: "Livré à l'ancien Keeper",
      },
      {
        value: AdminParcelEventReason.OTHER,
        label: "Autre",
      },
    ],
    secondOptions: [
      {
        value: ParcelStatus.IN_TRANSIT,
        label: "En transit",
      },
      {
        value: ParcelStatus.DELIVERED_TO_KEEPER,
        label: "Chez le Keeper",
      },
      {
        value: ParcelStatus.DELIVERED_TO_RECIPIENT,
        label: "Livré",
      },
    ],
  },
  {
    key: 100,
    text: "Colis annulé",
    value: AdminParcelEventType.CANCELLATION,
    active: POSSIBLE_STATUSES_ADMIN_EVENT_CANCELLATION.includes(parcel.status),
    options: [
      {
        value: AdminParcelEventReason.KEEPER_ABSENT,
        label: "Le Keeper était absent et le colis a été redirigé",
      },
      {
        value: AdminParcelEventReason.DELIVERED_TO_RECIPIENT,
        label: "Livré directement au destinataire",
      },
      {
        value: AdminParcelEventReason.DELIVERED_TO_PICKUP_POINT,
        label: "Livré en point relais classique",
      },
      {
        value: AdminParcelEventReason.RETURN_TO_SENDER,
        label: "Retour à l'expéditeur",
      },
      {
        value: AdminParcelEventReason.PARCEL_TRACKING_LOST,
        label: "Colis jamais reçu / perte de traçabilité",
      },
      ...(carrierName !== CarriersName.DHL
        ? []
        : [
            {
              value: AdminParcelEventReason.NO_AVAILABILITY_ON_GEMA,
              label: "Pas de disponibilité sur GEMA",
            },
          ]),
      {
        value: AdminParcelEventReason.OTHER,
        label: "Autre",
      },
    ],
  },
  {
    key: 12,
    text: "Ajouter un flag",
    value: "ADD_FLAG",
    active: parcel.flag === null,
    options: [
      {
        value: ParcelFlag.RECIPIENT_DELIVERY_TO_CONFIRM,
        label: displayParcelFlag(ParcelFlag.RECIPIENT_DELIVERY_TO_CONFIRM),
      },
      {
        value: ParcelFlag.UNSCANNED,
        label: displayParcelFlag(ParcelFlag.UNSCANNED),
      },
      {
        value: ParcelFlag.SLEEPING,
        label: displayParcelFlag(ParcelFlag.SLEEPING),
      },
      {
        value: ParcelFlag.RECIPIENT_REFUSED,
        label: displayParcelFlag(ParcelFlag.RECIPIENT_REFUSED),
      },
      {
        value: ParcelFlag.INFINITE_DELIVERY,
        label: displayParcelFlag(ParcelFlag.INFINITE_DELIVERY),
      },
      {
        value: ParcelFlag.REASSIGNMENT_NEEDED,
        label: displayParcelFlag(ParcelFlag.REASSIGNMENT_NEEDED),
      },
      {
        value: ParcelFlag.KEEPER_DROPOFF_FAILED,
        label: displayParcelFlag(ParcelFlag.KEEPER_DROPOFF_FAILED),
      },
      {
        value: ParcelFlag.KEEPER_COLLECT_FAILED,
        label: displayParcelFlag(ParcelFlag.KEEPER_COLLECT_FAILED),
      },
      {
        value: ParcelFlag.CARRIER_LONG_RETURN,
        label: displayParcelFlag(ParcelFlag.CARRIER_LONG_RETURN),
      },
      {
        value: ParcelFlag.KEEPER_NOT_AVAILABLE_FOR_PICKUP,
        label: displayParcelFlag(ParcelFlag.KEEPER_NOT_AVAILABLE_FOR_PICKUP),
      },
      {
        value: ParcelFlag.NO_RECIPIENT_CONTACT_DETAILS,
        label: displayParcelFlag(ParcelFlag.NO_RECIPIENT_CONTACT_DETAILS),
      },
      ...(![ParcelStatus.DELIVERED_TO_KEEPER, ParcelStatus.RETURN_TO_PLAN].includes(parcel.status)
        ? []
        : [
            {
              value: ParcelFlag.RETURN_TO_ORGANIZE,
              label: displayParcelFlag(ParcelFlag.RETURN_TO_ORGANIZE),
            },
          ]),
    ],
  },
  {
    key: 13,
    text: "Retirer le flag",
    value: "REMOVE_FLAG",
    active: parcel.flag !== null,
    options: [],
  },
]
