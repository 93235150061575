import React, { Fragment, ReactElement, useEffect, useMemo } from "react"
import { Col, Row } from "reactstrap"
import styled from "styled-components"
import moment from "moment"

import DateInput from "components/Form/DateInput"
import Select from "components/Form/Select"
import { Company } from "types/company.types"
import { hoursOptions } from "utils/formik/hoursOptions"
import { COLOR } from "utils/color"
import { Input } from "semantic-ui-react"
import { CarriersName } from "types/carrier.types"
import { LabelInput } from "components/Ops/CreateOrder.styled"
import { getTranslatedCarrierName } from "utils/getTranslatedCarrierName"

const ReturnFormTitle = styled.div`
  font-size: 14px;
  margin: 30px 0 10px 0;
  font-weight: 600;
`

const CollectionCarrierDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
`

const CollectionCarrierDetailsHelpUrl = styled.div`
  font-size: 12px;
  color: ${COLOR.BLACK};
  padding: 15px;
  background-color: ${COLOR.SUPER_LIGHT_GREY};
  border-radius: 20px;

  &:hover {
    color: ${COLOR.MEDIUM_GREY};
  }
`

const MeetingSlotForm = styled(Row)`
  align-items: flex-end;
  margin: 20px 0 10px 0;
`

const WhatsNextText = styled.div`
  font-size: 12px;
  font-weight: 600;
  margin: 30px 0;
`

const StyledInput = styled(Input)`
  padding: 0;
  height: 34px;
`

const StyledLabelInput = styled(LabelInput)`
  font-size: 10px;
  color: ${COLOR.MEDIUM_GREY};
`

interface ReturnFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any
  changeData: (key: string, value: string) => void
  collectionCarriers: { loaded: boolean; data: Company[] }
  setFormCompleted: (value: boolean) => void
  alreadyCollected: boolean
}

type CodeVariants = "cotransportationCode" | "referenceCode"

const ReturnForm = ({
  data,
  changeData,
  collectionCarriers,
  setFormCompleted,
  alreadyCollected,
}: ReturnFormProps): ReactElement => {
  const availableCarriers = collectionCarriers.data
  const chosenCarrier = data.carrier
  const collectionOptions = chosenCarrier?.data.collectionOptions

  /**
   * Get filters for meeting slot hours
   * @param type - Type of meeting slot (start or end)
   * @param value - Value
   *
   * @returns Possible options for a meeting slot edge
   */
  const getHoursSlotFilter = (type: string, value: string) => {
    if (type === "meetingSlotStart") {
      return (
        Number(value.substring(0, 2)) >= Number(collectionOptions?.minimumHour) &&
        Number(value.substring(0, 2)) <= Number(collectionOptions?.maximumHour)
      )
    }

    if (type === "meetingSlotEnd") {
      return (
        Number(value.substring(0, 2)) >=
          Number(data.meetingSlotStart?.value?.substring(0, 2)) + Number(collectionOptions?.minimumSlotDuration) &&
        Number(value.substring(0, 2)) <= Number(collectionOptions?.maximumHour)
      )
    }

    return true
  }

  const codeField = useMemo((): { name: CodeVariants; label: string } | null => {
    if (!data?.carrier?.label) return null

    switch (data?.carrier?.label) {
      case CarriersName.TUT_TUT:
        return {
          name: "cotransportationCode",
          label: "Code de cotransporteur",
        }
      case CarriersName.GLS:
        return {
          name: "referenceCode",
          label: "Le code de référence",
        }
      default:
        return null
    }
  }, [data?.carrier?.label])

  /**
   * Check when data is updated if the form is completed
   */
  useEffect(() => {
    // If parcel already collected OR the carrier is "Post Office French Post", we only need the carrier
    if (
      (data.carrier && alreadyCollected) ||
      chosenCarrier?.data?.name === CarriersName.POST_OFFICE ||
      chosenCarrier?.data?.name === CarriersName.RELAISCOLIS
    ) {
      setFormCompleted(true)
      return
    }

    if (
      data.carrier &&
      data.date &&
      moment(data.date).isAfter(moment()) &&
      data.meetingSlotStart &&
      data.meetingSlotEnd &&
      Number(data.meetingSlotEnd.value?.substring(0, 2)) >=
        Number(data.meetingSlotStart.value?.substring(0, 2)) + Number(collectionOptions?.minimumSlotDuration) &&
      (codeField ? !!data[codeField.name]?.length : true)
    ) {
      setFormCompleted(true)
    }

    if (
      Number(data.meetingSlotEnd?.value?.substring(0, 2)) <
        Number(data.meetingSlotStart?.value?.substring(0, 2)) + Number(collectionOptions?.minimumSlotDuration) ||
      moment(data?.date).isSameOrBefore(moment()) ||
      (codeField ? !data[codeField.name]?.length : false)
    ) {
      setFormCompleted(false)
    }
  }, [data, alreadyCollected, codeField])

  const onCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    changeData(codeField.name, value || "")
  }

  return (
    <Fragment>
      <ReturnFormTitle>🚛 Configuration du retour</ReturnFormTitle>
      {/** Carrier choice */}
      <Select
        value={data.carrier}
        onChange={carrier => changeData("carrier", carrier)}
        options={availableCarriers.map(carrier => ({
          label: getTranslatedCarrierName(carrier.name),
          value: carrier.id,
          data: carrier,
        }))}
        placeholder="Quel transporteur de retour ?"
      />

      {chosenCarrier?.value !== undefined &&
        chosenCarrier?.data?.name !== CarriersName.POST_OFFICE &&
        chosenCarrier?.data?.name !== CarriersName.RELAISCOLIS &&
        !alreadyCollected && (
          <Fragment>
            <CollectionCarrierDetails>
              <CollectionCarrierDetailsHelpUrl>
                📚 {"  "}
                <a href={collectionOptions?.helpUrl} target="_blank" rel="noreferrer">
                  Comment faire un retour de colis avec {chosenCarrier?.data.name} ?
                </a>
              </CollectionCarrierDetailsHelpUrl>
            </CollectionCarrierDetails>

            {/** Meeting slot */}
            <MeetingSlotForm>
              <Col xs={2}>
                <DateInput
                  label={"Date de collecte"}
                  value={data.date}
                  min={moment().format("YYYY-MM-DD")}
                  onChange={event => changeData("date", event.target.value)}
                />
              </Col>
              <Col xs={3}>
                <Select
                  value={data.meetingSlotStart}
                  isDisabled={chosenCarrier === undefined}
                  onChange={hour => changeData("meetingSlotStart", hour)}
                  options={hoursOptions.filter(({ value }) => getHoursSlotFilter("meetingSlotStart", value))}
                  placeholder="Début du créneau"
                  label="Début du créneau"
                />
              </Col>
              <Col xs={3}>
                <Select
                  value={data.meetingSlotEnd}
                  isDisabled={chosenCarrier === undefined || data.meetingSlotStart?.value === undefined}
                  onChange={hour => changeData("meetingSlotEnd", hour)}
                  options={hoursOptions.filter(({ value }) => getHoursSlotFilter("meetingSlotEnd", value))}
                  placeholder="Fin du créneau"
                  label="Fin du créneau"
                />
              </Col>
              {codeField && (
                <Col xs={3}>
                  <StyledLabelInput>{codeField.label}</StyledLabelInput>
                  <StyledInput
                    fluid
                    placeholder={codeField.label}
                    required
                    value={data[codeField.name] || ""}
                    onChange={onCodeChange}
                  />
                </Col>
              )}
            </MeetingSlotForm>
          </Fragment>
        )}

      {chosenCarrier?.value !== undefined &&
        data.meetingSlotStart !== undefined &&
        data.meetingSlotEnd !== undefined && (
          <Fragment>
            {collectionOptions.integrationType === "API" && (
              <WhatsNextText>
                👉 L&apos;ordre de transport va être envoyé automatiquement au transporteur.
              </WhatsNextText>
            )}

            {collectionOptions.integrationType === "Platform" && (
              <WhatsNextText>
                👉 Pour terminer : rendez vous sur{" "}
                <a
                  href={collectionOptions?.carrierPlatformUrl}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "underline" }}
                >
                  {collectionOptions?.carrierPlatformUrl}
                </a>{" "}
                pour passer l&apos;ordre de transport.
              </WhatsNextText>
            )}

            {collectionOptions.integrationType === "Mail" && (
              <WhatsNextText>
                👉 Pour terminer : vous devez maintenant envoyer un mail à au transporteur afin de passer l&apos;ordre
                de transport.
              </WhatsNextText>
            )}
          </Fragment>
        )}
    </Fragment>
  )
}

export default ReturnForm
