import { AdminParcelEventReason } from "types/parcel.types"

/**
 * When adding a new event reason, follow these steps:
 * 1. Create a new 'else if' block for the event reason using 'eventReasonType'.
 * 2. Define the appropriate description for the new reason.
 */
export function getEventReason(eventReasonType: string): string {
  if (eventReasonType === AdminParcelEventReason.KEEPER_DID_NOT_SCAN) {
    return "Le Keeper n'a pas pu scanner"
  } else if (eventReasonType === AdminParcelEventReason.DELIVERED_TO_PICKUP_POINT) {
    return "Livré en point relais classique"
  } else if (eventReasonType === AdminParcelEventReason.DELIVERED_TO_RECIPIENT) {
    return "Le destinataire a récupéré son colis"
  } else if (eventReasonType === AdminParcelEventReason.PARCEL_TRACKING_LOST) {
    return "Colis jamais reçu / perte de traçabilité"
  } else if (eventReasonType === AdminParcelEventReason.MANUAL_CREATION_OTHER) {
    return "Création manuelle autre"
  } else if (eventReasonType === AdminParcelEventReason.RETURN_TO_SENDER) {
    return "Retour à l'expéditeur"
  } else if (eventReasonType === AdminParcelEventReason.RETURN_TO_SHOP) {
    return "Retourner à la boutique"
  } else if (eventReasonType === AdminParcelEventReason.NO_ANNOUNCEMENT) {
    return "Pas d'annonce"
  } else if (eventReasonType === AdminParcelEventReason.KEEPER_ABSENT) {
    return "Le Keeper était absent"
  } else if (eventReasonType === AdminParcelEventReason.MISSING_ADDRESS_DATA) {
    return "Données d'adresse manquantes"
  } else if (eventReasonType === AdminParcelEventReason.REFUSED_BY_KEEPER) {
    return "Refusé par le Keeper"
  } else if (eventReasonType === AdminParcelEventReason.REFUSED_BY_RECIPIENT) {
    return "Colis refusé par le destinataire"
  } else if (eventReasonType === AdminParcelEventReason.RECIPIENT_ABSENT) {
    return "Destinataire injoignable"
  } else if (eventReasonType === AdminParcelEventReason.CARRIER_DID_NOT_SHOW_UP) {
    return "Echec de collecte transporteur"
  } else if (eventReasonType === AdminParcelEventReason.KEEPER_MISSED_APPOINTMENT) {
    return "Echec de collecte Keeper"
  } else if (eventReasonType === AdminParcelEventReason.CARRIER_CHANGED_AVAILABILITY) {
    return "Report de la collecte par le transporteur"
  } else if (eventReasonType === AdminParcelEventReason.KEEPER_ASKED_RESCHEDULE) {
    return "Report de la collecte par le keeper"
  } else if (eventReasonType === AdminParcelEventReason.DELIVERY_OUT_OF_KEEPER_AVAILABILITIES) {
    return "Livraison hors dispo Keeper"
  } else if (eventReasonType === AdminParcelEventReason.KEEPER_CHANGED_AVAILABILITIES) {
    return "Changement dispo Keeper"
  } else if (eventReasonType === AdminParcelEventReason.DELIVERED_TO_PREVIOUSLY_ASSIGNED_KEEPER) {
    return "Livré à l'ancien Keeper"
  } else if (eventReasonType === AdminParcelEventReason.ALREADY_COLLECTED_BY_CARRIER) {
    return "Déjà collecté par le transporteur"
  } else if (eventReasonType === AdminParcelEventReason.OTHER) {
    return "Autre"
  } else {
    return eventReasonType
  }
}
