import React, { ReactElement, useState } from "react"
import { Button, Header, Icon, Loader, Modal } from "semantic-ui-react"

import { CancelModalContent, ModalLoader } from "./CancelModal.styled"

interface CancelModalProps {
  title: string
  subtitle?: string
  trigger: React.ReactNode
  onConfirm?: () => void
  onCancel?: () => void
  isLoading?: boolean
}

export const CancelModal = ({
  title,
  subtitle,
  onConfirm,
  onCancel,
  trigger,
  isLoading,
}: CancelModalProps): ReactElement => {
  const [open, setOpen] = useState(false)

  return (
    <Modal basic onClose={() => setOpen(false)} onOpen={() => setOpen(true)} open={open} size="small" trigger={trigger}>
      <Header icon>
        <Icon name="attention" />
        {title}
      </Header>
      <CancelModalContent>{subtitle || ""}</CancelModalContent>
      <Modal.Actions>
        <Button
          basic
          color="red"
          inverted
          onClick={() => {
            setOpen(false)
            onCancel && onCancel()
          }}
        >
          <Icon name="remove" /> Non !
        </Button>
        <Button
          color="green"
          inverted
          onClick={() => {
            setOpen(false)
            onConfirm && onConfirm()
          }}
        >
          <Icon name="checkmark" /> Confirmer
        </Button>
      </Modal.Actions>
      {isLoading && (
        <ModalLoader>
          <Loader className="black-loader" inline active />
        </ModalLoader>
      )}
    </Modal>
  )
}
