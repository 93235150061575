import React, { ReactElement, useState } from "react"
import { TransferLine } from "./TransferLine"
import { ButtonContainer, Section, SectionTitle } from "./PerformedTransfersHistory.styled"
import { useDispatch, useSelector } from "react-redux"
import { ReduxState } from "types/reduxState.types"
import { Button } from "semantic-ui-react"
import { apiGetAllPayments } from "services/payments"
import { LoadingComponent } from "components/LoadingComponent"
import { PaymentTransfer } from "types/payments.types"
import { WIRE_TRANSFERS_LIMIT } from "../data/constants"

export const PerformedTransfersHistory = (): ReactElement => {
  const dispatch = useDispatch()
  const paymentsAll = useSelector((state: ReduxState) => state.payments.listAll)
  const [openedTransferId, setOpenedTransferId] = useState("")

  const paymentsTransfers = paymentsAll?.data?.done
  const loadingMore = paymentsAll.loadingMore
  const moreToLoad = paymentsAll.moreToLoad

  const loadMore = () => {
    if (!paymentsTransfers?.length) return

    const paymentCursor = paymentsTransfers.at(0).cursor
    dispatch(apiGetAllPayments({ limit: WIRE_TRANSFERS_LIMIT, cursor: paymentCursor }))
  }

  const handleOpenedTransfer = (transferId: string) =>
    setOpenedTransferId(openedTransferId === transferId ? "" : transferId)

  const sortPaymentsTransfers = (paymentsTransfersToSort: PaymentTransfer[]) => {
    if (!paymentsTransfersToSort?.length) return []

    if (paymentsTransfersToSort.length === 1) return paymentsTransfersToSort

    return paymentsTransfersToSort
      .slice()
      .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
  }

  const paymentsTransferSorted = sortPaymentsTransfers(paymentsTransfers)

  return (
    <Section>
      <SectionTitle>Historique</SectionTitle>
      {paymentsTransferSorted.map((transfer: PaymentTransfer) => (
        <TransferLine
          key={transfer.id}
          transfer={transfer}
          handleOpenedTransfer={handleOpenedTransfer}
          isOpened={openedTransferId === transfer.packmanBatchId}
        />
      ))}

      {loadingMore ? (
        <LoadingComponent />
      ) : paymentsTransfers.length && moreToLoad ? (
        <ButtonContainer>
          <Button onClick={loadMore}>Charger plus</Button>
        </ButtonContainer>
      ) : (
        <></>
      )}
    </Section>
  )
}
