import React, { ReactElement, useState } from "react"
import { Col, Row } from "reactstrap"
import { DetailLineContainer, DetailText, RevertButton } from "./PerformedTransfersHistory.styled"
import { useDispatch } from "react-redux"
import { apiGetNewPayments, apiRevertPayment, updatePayments } from "services/payments"
import { PaymentExtended } from "types/payments.types"
import { WIRE_TRANSFERS_LIMIT } from "../data/constants"

interface DetailLineProps {
  paymentFromBatch: PaymentExtended
  canRevertPayment?: boolean
  packmanBatchId?: string
  totalCount?: number
}

export const DetailLine = ({ paymentFromBatch, canRevertPayment = false }: DetailLineProps): ReactElement => {
  const dispatch = useDispatch()
  const [isDisabled, setIsDisabled] = useState(false)

  const handleRevertPayment = async () => {
    try {
      setIsDisabled(true)
      const res = await dispatch(
        apiRevertPayment({ paymentId: paymentFromBatch.id, packmanBatchId: paymentFromBatch?.packmanBatchId }),
      )

      // @ts-ignore
      if (res?.payload?.request?.status !== 200) return

      dispatch(updatePayments({ paymentId: paymentFromBatch.id, packmanBatchId: paymentFromBatch?.packmanBatchId }))
      dispatch(apiGetNewPayments({ limit: WIRE_TRANSFERS_LIMIT }))
    } catch (e) {
      setIsDisabled(false)
      console.error(e)
    }
  }

  return (
    <DetailLineContainer>
      <Row>
        <Col xs={3} sm={3} md={2}>
          <DetailText>
            {paymentFromBatch?.firstName} {paymentFromBatch?.lastName}
          </DetailText>
        </Col>
        <Col xs={3} sm={2} lg={1}>
          <DetailText>{paymentFromBatch.amount / 100}€</DetailText>
        </Col>
        {canRevertPayment && (
          <Col sm={5}>
            <RevertButton disabled={isDisabled} onClick={handleRevertPayment}>
              Repasser en “Virement à effectuer”
            </RevertButton>
          </Col>
        )}
      </Row>
    </DetailLineContainer>
  )
}
