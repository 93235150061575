import { ReturnRequestItem, ReturnRequestItemKey, ReturnRequestItemValue } from "./ReturnRequestModal.styled"
import React from "react"

interface ReturnRequestModalTextField {
  value: string | Object
  label: string
}

export const ReturnRequestModalTextField = ({ value, label }: ReturnRequestModalTextField) => {
  if (!value || value instanceof Object) {
    return null
  }
  return (
    <ReturnRequestItem>
      <ReturnRequestItemKey>{`${label}: `} </ReturnRequestItemKey>
      <ReturnRequestItemValue>
        <span>{value}</span>
      </ReturnRequestItemValue>
    </ReturnRequestItem>
  )
}
