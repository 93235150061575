import { gql } from "@apollo/client"

export interface RequestValidationInput {
  payload: { requestId: string; [key: string]: string }
}

export const validateReturnRequest = gql`
  mutation validateReturnRequest($payload: RequestValidationInput!) {
    validateReturnRequest(payload: $payload)
  }
`

export const removeReturnRequest = gql`
  mutation removeReturnRequest($requestId: String!) {
    removeReturnRequest(requestId: $requestId)
  }
`
